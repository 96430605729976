<template>
  <div class="app">
        <router-view></router-view>
    </div>
</template>

<style>
    *,
    *::before,
    *::after{
        box-sizing: border-box;
    }
    *{
        margin: 0;
        padding: 0;
    }
    @font-face {
        font-family: 'Mont';
        src: url('@/fonts/Mont-Regular.ttf') format('truetype'), url('@/fonts/Mont-Regular.woff') format('woff'), url('@/fonts/Mont-Regular.woff2') format('woff2');
        font-weight: 400;
    }
    @font-face {
        font-family: 'Mont';
        src: url('@/fonts/Mont-SemiBold.ttf') format('truetype'), url('@/fonts/Mont-SemiBold.woff') format('woff'), url('@/fonts/Mont-SemiBold.woff2') format('woff2');
        font-weight: 600;
    }
    @font-face {
        font-family: 'Mont';
        src: url('@/fonts/mont_extralightdemo.ttf') format('truetype');
        font-weight: 300;
    }
    @font-face {
        font-family: 'Muller';
        src: url('@/fonts/MullerBold.woff2') format('woff2');
        font-weight: 700;
    }
    body{
        font-family: 'Mont';
    }
    a, button{
        color: #000;
        text-decoration: none;
    }
    button{
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-family: 'Mont';
    }
    h1, h2, h3, h4, h5, h6{
        line-height: 100%;
    }
    .btn{
        /* border: 2px solid #272727;
        border-radius: 60px;
        font-size: 18px;
        transition: all linear .2s;
        color: #272727; */
        border-radius: 60px;
        font-size: 18px;
        color: #272727;
        border: 2px solid #272727;
        
        /* background: 
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(to right, #272727, #272727) border-box; */
        transition: all linear .2s;
        cursor: pointer;
    }
    .btn:hover{
        /* background-color: #272727;
        color: #fff; */
        border-color: transparent;
        background: 
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(-45deg,
                #cb9b51 22%, 
            #F6BC57 45%,
            #b3ac58 50%,
            #F6BC57 55%,
            #cb9b51 78%) border-box;
        color:#F6BC57;
    }
    .input{
        outline: none;
        border: none;
        font-size: 18px;
        color: #b1b1b1;
        font-family: 'Mont';
        background-color: #E8E8E8;
        border-radius: 24px;
        padding: 19px 30px;
    }
    .container{
        max-width: 1270px;
        padding: 0 15px;
        margin: 0 auto;
    }
    .wide-container{
        max-width: 1630px;
        padding: 0 15px;
        margin: 0 auto;
    }
    .offset{
        margin-bottom: 80px;
    }
    .heading-offset{
        margin-bottom: 80px;
    }
    .heading{
        font-size: 60px;
        font-weight: 600;
        line-height: 100%;
    }
    .spec-font{
        font-family: 'Times New Roman', Times, serif;
    }
    .footer-bg{
        background-color: #242424;
    }
    /* page banners */
    .page-banner{
        display: flex;
        align-items: center;
        background: url(./images/pages/banner.png) no-repeat;
        background-size: 100% 100%;
        height: calc(100vh - 160px);
        margin-bottom: 80px;
    }
    .page-banner__inner{
        width: 100%;
    }
    .page-banner__name{
        font-size: 25px;
        color: #fff;
        margin-bottom: 30px;
        line-height: 100%;
    }
    .page-banner__heading{
        font-size: 100px;
        color: #fff;
        margin-bottom: 30px;
        text-transform: uppercase;
    }
    .page-banner__text{
        font-size: 25px;
        color: #fff;
    }
    /* steps */
    /* .page-steps__heading{
        text-align: center;
        margin-bottom: 80px;
    }
    .page-steps__inner{
        display: grid;
        gap: 60px 25px;
        grid-template-columns: repeat(2, 1fr);
        max-width: 1355px;
        padding: 0 15px;
        margin: 0 auto;
    }
    .page-steps__item{
        position: relative;
        padding: 60px 50px 45px 75px;
        border: 2px solid #DEDEDE;
    }
    .page-steps__item::before{
        position: absolute;
        height: 65px;
        width: 85px;
        top: -40px;
        font-size: 60px;
        font-weight: 600;
        background-color: #fff;
        padding: 0 15px;
    }
    .page-steps__item_1::before{
        content: "01";
    }
    .page-steps__item_2::before{
        content: "02";
    }
    .page-steps__item_3::before{
        content: "03";
    }
    .page-steps__item_4::before{
        content: "04";
    }
    .steps-item__heading{
        font-size: 25px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 35px;
    }
    .steps-item__text{
        font-size: 18px;
    } */
    .checkbox__wrapper{
        display: flex;
        align-items: center;
    }
    .checkbox__ico{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        margin-bottom: -2px;
        border: 1px solid #000;
        border-radius: 4px;
        cursor: pointer;
    }
    .checkbox__input{
        display: none;
    }
    .checkbox_select{
        background-color: #000;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        top: 3px;
        right: 6px;
        transform: rotate(35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 7px;
        width: 2px;
        bottom: 4px;
        left: 5px;
        transform: rotate(-35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox__label{
        cursor: pointer;
    }
    .admin-navigation{
        width: 100%;
        max-width: 300px;
    }
    /* Крестик */
    .cross{
        position: absolute;
        top: 30px;
        right: 10px;
        height: 40px;
        width: 40px;
    }
    .cross::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background-color: #272727;
        top: 12px;
        right: 0;
        transform: rotate(45deg);
    }
    .cross::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 40px;
        background-color: #272727;
        top: 12px;
        right: 0;
        transform: rotate(-45deg);
    }
    .loading{
        opacity: .5;
        pointer-events: none;
    }
    .invalid{
        border-color: red;
    }
    @media (max-width: 768px){
        .page-steps__inner{
            grid-template-columns: repeat(1, 1fr);
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    @media (max-width: 600px) {
        .container{
            padding: 0 10px;
        }
        .wide-container{
            padding: 0 10px;
        }
        .heading{
            font-size: 26px;
        }
        .offset{
            margin-bottom: 60px;
        }
        .heading-offset{
            margin-bottom: 40px;
        }
        .input{
            font-size: 16px;
        }
        .page-banner{
            background: url(./images/banner-mob-p.png) no-repeat;
            background-size: 100% 100%;
            height: calc(100vh - 160px);
            margin-bottom: 60px;
        }
        /* Этапы */
        .page-banner__name{
            font-size: 16px;
            margin-bottom: 25px;
        }
        .page-banner__heading{
            font-size: 26px;
            margin-bottom: 25px;
        }
        .page-banner__text{
            font-size: 16px;
        }
        .page-steps__heading{
            text-align: left;
            padding-left: 10px;
            margin-bottom: 50px;
        }
        .page-steps__inner{
            gap: 40px;
        }
        .page-steps__item{
            padding: 35px 30px 40px 30px;
        }
        .page-steps__item::before{
            font-size: 30px;
            top: -20px;
            height: 20px;
            width: 30px;
            width: 54px;
            padding: 0 10px;
        }
        .steps-item__heading{
            font-size: 16px;
            margin-bottom: 25px;
        }
        .steps-item__text{
            font-size: 16px;
        }
        .header-bg{
            background-color: #242424;
        }
    }
</style>
